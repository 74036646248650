<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/categorias"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <CategoriaFormulario
          :categorias="categorias"
          @send="editar"
          :categoria="categoria"
          :loadingBtn="loadingBtn"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Categoria editada com sucesso"
      @close="goToCategorias"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import CategoriaFormulario from "@/components/categorias/CategoriaFormulario.vue";
import {
  editarCategoria,
  exibirCategoria,
  todasCategorias,
} from "@/services/categorias";
export default {
  name: "CategoriasEditar",
  components: { CategoriaFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Categorias",
        disabled: false,
        to: "/categorias",
      },
      {
        text: "Editar Categorias",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    categoria: {},
    categorias: [],
    loading: false,
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.getCategorias();
    this.exibir();
  },
  methods: {
    async editar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarCategoria(this.$route.params.id, {
          nome: event.nome,
          categoriasId: event.categoriasId ? event.categoriasId[0].id : null,
        }).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response;
      }
    },
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await exibirCategoria(this.$route.params.id);
        this.categoria = resp.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async getCategorias() {
      this.categorias = [];
      try {
        const resp = await todasCategorias();
        this.categorias = resp.data;
      } catch (e) {
        console.error(e);
      }
    },
    goToCategorias() {
      this.sucess = false;
      this.$router.push({ name: "Categorias" });
    },
  },
};
</script>

<style></style>
